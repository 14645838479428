import _ from 'lodash';

export const createJumpLinks = ({ models, companyListSize }) => {
    let jumpLinks = [];

    _.forEach(models, (model) => {
        if (_.isArray(model)) {
            jumpLinks = _.concat(jumpLinks, createJumpLinks({ models: model, companyListSize }));
        }

        if (model.navText) {
            if (_.get(model, '__typename') === 'graphCMS_FeaturedCompanySection') {
                _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
                const templaterNavText = _.template(model.navText);
                let companyCountDisplay = _.min([
                    companyListSize,
                    _.get(model, 'maxNumberDisplayed'),
                ]);
                if (companyCountDisplay < 5) {
                    companyCountDisplay = '';
                }

                // eslint-disable-next-line no-param-reassign
                model.navText = templaterNavText({
                    count: companyCountDisplay,
                });
            }

            jumpLinks.push({
                to: `#${_.kebabCase(model.navText)}`,
                // Regex to replace double spaced string
                navText: _.replace(model.navText, /  +/g, ' '),
            });
        }
    });
    return jumpLinks;
};

export const createCitySchema = ({ city, path }) => {
    const {
        localBusinessName,
        localBusinessStreet,
        localBusinessCity,
        localBusinessState,
        localBusinessZip,
        localBusinessGoogleLocalUrl,
        localBusinessPhone,
        reviews,
    } = city;

    const keyMap = {
        localBusinessCity: 'addressLocality',
        localBusinessState: 'addressRegion',
        localBusinessZip: 'postalCode',
        localBusinessStreet: 'streetAddress',
        localBusinessName: 'name',
        localBusinessPhone: 'telephone',
        localBusinessGoogleLocalUrl: 'sameAs',
    };

    const filteredAddressInfo = _.pickBy(
        {
            localBusinessStreet,
            localBusinessCity,
            localBusinessState,
            localBusinessZip,
        },
        _.identity,
    );

    const filteredBusinessInfo = _.pickBy(
        {
            localBusinessName,
            localBusinessGoogleLocalUrl,
            localBusinessPhone,
        },
        _.identity,
    );

    if (_.isEmpty(filteredAddressInfo) && _.isEmpty(filteredBusinessInfo)) {
        return {};
    }

    let cityBusinessData = {};

    _.forEach(filteredAddressInfo, (value, key) => {
        const newKey = keyMap[key];
        if (value) {
            cityBusinessData = {
                ...cityBusinessData,
                address: {
                    ...cityBusinessData.address,
                    [newKey]: value,
                },
            };
        }
    });

    _.forEach(filteredBusinessInfo, (value, key) => {
        const newKey = keyMap[key];
        if (value) {
            cityBusinessData = {
                ...cityBusinessData,
                [newKey]: key === 'localBusinessGoogleLocalUrl' ? [value] : value,
            };
        }
    });

    let schema = {
        '@context': 'http://schema.org',
        '@id': `https://www.wikilawn.com${path}`,
        '@type': 'LocalBusiness',
        ...cityBusinessData,
        openingHours: 'Mo,Tu,We,Th,Fr,Sa,Su 08:00-21:00',
        url: `https://www.wikilawn.com${path}`,
        description: 'The world’s #1 online resource for lawn care.',
        priceRange: '$15-60',
        image: `${process.env.SITE_URL}/wikilawn.png`,
        logo: `${process.env.SITE_URL}/wikilawn.png`,
    };

    if (_.size(reviews)) {
        const averageRating = _.meanBy(reviews, (review) => {
            return review.rating;
        });

        const reviewsSchema = _.map(
            reviews.slice(0, 12),
            ({
                date, text, rating, customerName,
            }) => {
                const reviewSchema = {
                    '@type': 'Review',
                    reviewRating: {
                        '@type': 'Rating',
                        ratingValue: rating,
                    },
                    datePublished: date,
                    description: text,
                    author: {
                        '@type': 'Thing',
                        name: customerName,
                    },
                };

                return reviewSchema;
            },
        );

        schema = {
            ...schema,
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: averageRating,
                reviewCount: _.size(reviews),
            },
            review: [...reviewsSchema],
        };
    }

    return schema;
};

export const generateServiceCityLinkData = ({ city, service }) => {
    const citySlug = city.slug;
    const cityName = city.name;
    const stateSlug = _.get(city, 'state.slug');

    // This is a special case for Washington, DC due to the fact it does not belong to a state
    if (!stateSlug) {
        return {
            to: `/${service.slug}/${citySlug}/`,
            linkText: _.upperFirst(cityName),
        };
    }

    return {
        to: `/${service.slug}/${stateSlug}/${citySlug}/`,
        linkText: `${_.startCase(cityName)}, ${_.toUpper(stateSlug)}`,
    };
};
