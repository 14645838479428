import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import HomePageFooterStyles from './homePageFooter.module.scss';
import FooterCopyright from '../footerCopyright/footerCopyright';
import Logo from '../../assets/footer-logo.svg';

const footerMenu = [
    { title: 'Locations', path: '/locations/' },
    { title: 'About Us', path: '/about/' },
    { title: 'Contact Us', path: '/contact/' },
    { title: 'Scholarship', path: '/scholarship/' },
];

const HomePageFooter = ({ states, hasLeadCollection }) => {
    const {
        upperSection,
        upperSectionContent,
        logoAndMission,
        menu,
        statesAndDisclaimer,
        sectionHeader,
        links,
        stateList,
    } = HomePageFooterStyles;

    return (
        <footer data-testid="home-page-footer">
            <section className={upperSection}>
                <div className={upperSectionContent}>
                    <div className={logoAndMission}>
                        <Logo />
                        <p>
                            Wikilawn is on a mission to build the world’s #1 online resource for
                            lawn & garden gurus and professionals.
                        </p>
                    </div>
                    <div className={menu}>
                        <span className={sectionHeader}>Menu</span>
                        <ul>
                            {_.map(footerMenu, (item) => {
                                return (
                                    <li key={item.path}>
                                        <Link to={item.path} className={links}>
                                            {item.title}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className={statesAndDisclaimer}>
                        <div>
                            <span className={sectionHeader}>Lawn Care by State</span>
                            <ul className={stateList}>
                                {_.map(states, (state) => {
                                    return (
                                        <li key={state.id}>
                                            <Link
                                                to={`/lawn-care/${state.slug}/`}
                                                className={links}
                                                data-testid="state-link"
                                            >
                                                {_.toUpper(state.slug)}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div>
                            <span>
                                *Wikilawn or its partners may contact you by phone, SMS, or email at
                                the email address or phone number you provide, including for
                                marketing purposes. Wikilawn may earn a commission for services you
                                purchase by following a link on Wikilawn‘s site.
                            </span>
                            <span>
                                *Wikilawn is a participant in the Amazon Services LLC Associates
                                Program, an affiliate advertising program designed to provide a
                                means for sites to earn advertising fees by advertising and linking
                                to Amazon.com.
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <FooterCopyright hasLeadCollection={hasLeadCollection} />
        </footer>
    );
};

HomePageFooter.propTypes = {
    states: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    })).isRequired,
    hasLeadCollection: PropTypes.bool,
};

HomePageFooter.defaultProps = {
    hasLeadCollection: false,
};

export default HomePageFooter;
