import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import footerStyles from './footer.module.scss';
import FooterCopyright from '../footerCopyright/footerCopyright';
import { generateServiceCityLinkData } from '../../helpers';

const Footer = ({ hasLeadCollection }) => {
    const data = useStaticQuery(graphql`
        query LocationsFooterQuery {
            graphCMS {
                featuredLocationLists(last: 1) {
                    id
                    service {
                        id
                        slug
                    }
                    cities {
                        id
                        slug
                        name
                        state {
                            slug
                        }
                    }
                }
            }
        }
    `);

    const { featuredLocationLists } = data.graphCMS;
    const { cities, service } = _.head(featuredLocationLists);

    const {
        footerContainer,
        locationSection,
        locationList,
        locationLink,
    } = footerStyles;

    return (
        <footer className={footerContainer} data-testid="footer">
            <section className={locationSection}>
                <div className={locationList}>
                    {_.map(cities, (city) => {
                        const { to, linkText } = generateServiceCityLinkData({ city, service });

                        return (
                            <Link
                                key={to}
                                to={to}
                                data-testid={to}
                                className={locationLink}
                                activeClassName="activeLink"
                            >
                                {linkText}
                            </Link>
                        );
                    })}
                    <Link
                        key="locations"
                        to="/locations/"
                        data-testid="locations"
                        className={locationLink}
                        activeClassName="activeLink"
                    >
                        Locations
                    </Link>
                </div>
            </section>
            <FooterCopyright hasLeadCollection={hasLeadCollection} />
        </footer>
    );
};

Footer.propTypes = {
    hasLeadCollection: PropTypes.bool,
};

Footer.defaultProps = {
    hasLeadCollection: false,
};

export default Footer;
