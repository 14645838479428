import _ from 'lodash';

function getDetailsByPlaceId(placeId) {
    // We need a map instance to work with places service
    if (!window.placesMap) {
        window.placesMap = new window.google.maps.Map(document.createElement('div'));
    }

    const places = new window.google.maps.places.PlacesService(window.placesMap);

    return new Promise((resolve, reject) => {
        places.getDetails({ placeId }, (result, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
            }

            resolve(result);
        });
    });
}

function placeGet({ keys, short, addressComponentsByType }) {
    let place = '';
    // eslint-disable-next-line consistent-return
    _.forEach(keys, (key) => {
        if (key in addressComponentsByType) {
            place = short
                ? addressComponentsByType[key].short_name
                : addressComponentsByType[key].long_name;
            return false;
        }
    });
    return place;
}

function formatAddressComponents({ place = {} }) {
    return _.reduce(
        place.address_components || [],
        (acc, data) => {
            _.forEach(data.types, (type) => {
                acc[type] = data;
            });
            return acc;
        },
        {},
    );
}

function formatPlace({ place = {} }) {
    const addressComponentsByType = formatAddressComponents({ place });
    // assign required here to keep object reference happy for tests
    const result = _.assign(place, {
        street_number: placeGet({ keys: ['street_number'], addressComponentsByType }),
        street_name: placeGet({ keys: ['route'], addressComponentsByType }),
        city: placeGet({
            keys: [
                'locality',
                'sublocality',
                'sublocality_level_1',
                'neighborhood',
                'administrative_area_level_3',
                'administrative_area_level_2',
            ],
            addressComponentsByType,
        }),
        state: placeGet({
            keys: ['administrative_area_level_1'],
            short: true,
            addressComponentsByType,
        }),
        zip: placeGet({ keys: ['postal_code'], addressComponentsByType }),
        country: placeGet({ keys: ['country'], short: true, addressComponentsByType }),
    });

    if (
        _.isFunction(_.get(place, 'geometry.location.lat'))
        && _.isFunction(_.get(place, 'geometry.location.lng'))
    ) {
        result.lat = place.geometry.location.lat();
        result.lng = place.geometry.location.lng();
    }

    result.street = _.trim(`${result.street_number} ${result.street_name}`);

    return result;
}

// eslint-disable-next-line import/prefer-default-export
export const lsAddressHelpers = {
    _private: {
        placeGet,
        formatAddressComponents,
    },
    formatPlace,
    getDetailsByPlaceId,
};
