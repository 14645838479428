export const defaultLinks = [
    {
        to: '/',
        navText: 'Home',
    },
    {
        to: '/lawn-care/',
        navText: 'Lawn Care',
    },
    {
        to: '/about/',
        navText: 'About Us',
    },

    {
        to: '/contact/',
        navText: 'Contact Us',
    },
    {
        to: '/scholarship/',
        navText: 'Scholarship',
    },
];

export const leadCollectionServices = [
    {
        label: 'Lawn Mowing',
        value: 196,
    },
    {
        label: 'Landscaping',
        value: 196,
    },
    {
        label: 'Fertilization',
        value: 196,
    },
    {
        label: 'Other',
        value: null,
    },

];

export const services = [
    {
        label: 'Lawn Mowing',
        value: 196,
    },
    {
        label: 'Bush Trimming',
        value: 202,
    },
    {
        label: 'Tree Trimming',
        value: 202,
    },
    {
        label: 'Fertilization',
        value: 196,
    },
    {
        label: 'Aeration',
        value: 451,
    },
    {
        label: 'Cleanup',
        value: 199,
    },
    {
        label: 'Mulching',
        value: 196,
    },
    {
        label: 'Flower Bed Weeding',
        value: 209,
    },
    {
        label: 'Gutter Cleaning',
        value: 295,
    },
    {
        label: 'Leaf Removal',
        value: 199,
    },
    {
        label: 'Landscaping',
        value: 196,
    },
    {
        label: 'Roofing',
        value: 9,
    },
    {
        label: 'Pest Control',
        value: 102,
    },

];
