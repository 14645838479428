import React from 'react';
import PropTypes from 'prop-types';
import hamburgerStyles from './hamburger.module.scss';

const Hamburger = ({
    setShowMobileNav, showMobileNav, setShowDropDown, setShowMobileDropDown,
}) => {
    const {
        hamburgerContainer,
        hamburgerTop,
        hamburgerMiddle,
        hamburgerBottom,
        hamburgerLine,
        hamburgerMobileActive,
    } = hamburgerStyles;

    const toggleMenus = () => {
        setShowMobileNav(!showMobileNav);
        setShowDropDown(0);
        setShowMobileDropDown(0);
    };

    return (
        <div
            data-testid="hamburger"
            tabIndex="0"
            role="button"
            aria-pressed="false"
            aria-label="Menu"
            className={`${hamburgerContainer} ${showMobileNav ? hamburgerMobileActive : ''} link`}
            onKeyDown={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                    toggleMenus();
                }
            }}
            // this is to prevent focus outline when clicking
            onMouseDown={(e) => {
                e.preventDefault();
            }}
            // we also need this as onMouseDown can fire twice on mobile
            onClick={() => {
                toggleMenus();
            }}
        >
            <span
                className={`${hamburgerTop} 
                ${hamburgerLine} link`}

            />
            <span
                className={`${hamburgerMiddle} 
                ${hamburgerLine} link`}
            />
            <span
                className={`${hamburgerBottom} 
                ${hamburgerLine} link`}
            />
        </div>
    );
};

Hamburger.propTypes = {
    setShowMobileNav: PropTypes.func.isRequired,
    setShowDropDown: PropTypes.func.isRequired,
    setShowMobileDropDown: PropTypes.func.isRequired,
    showMobileNav: PropTypes.bool.isRequired,
};

export default Hamburger;
