import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import '../../scss/main.scss';
import loadable from '@loadable/component';
import layoutStyles from './layout.module.scss';
import Loading from '../loading/loading';
import Header from '../header/header';
import Footer from '../footer/footer';
import HomePageFooter from '../homePageFooter/homePageFooter';
import JumpLinkButton from '../jumpLinkButton/jumpLinkButton';
import { defaultLinks } from '../../constants';
import SEO from '../seo';
import { LayoutProvider } from './layoutContext';

// eslint-disable-next-line arrow-body-style
const LeadCollection = loadable(() => import('../leadCollection/leadCollection'));

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
        offset: 120,
        speed: 250,
        speedAsDuration: true,
    });
}

const Layout = ({
    isHomePage,
    states,
    children,
    links,
    leadCollectionProps,
    metaDescription,
    metaTitle,
    metaPageNumber,
    meta,
    noindex,
    schemaOrgJSONLD,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const {
        outerContainer, layoutContainer, upperWrapper, getQuotesCta, hideCta,
    } = layoutStyles;
    const loadingMethods = { setIsLoading, setHasCompleted };

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    const setScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', setScroll);

        return () => {
            window.removeEventListener('scroll', setScroll);
        };
    }, []);

    const metaTitleWithPageNumber = metaPageNumber > 1 ? `${metaTitle} | Page ${metaPageNumber}` : metaTitle;

    return (
        <LayoutProvider value={loadingMethods}>
            <div className={outerContainer}>
                <div className={upperWrapper}>
                    <SEO
                        schemaOrgJSONLD={schemaOrgJSONLD}
                        title={metaTitleWithPageNumber}
                        description={metaDescription}
                        meta={meta}
                        noindex={noindex}
                    />
                    {isLoading || hasCompleted ? <Loading hasCompleted={hasCompleted} /> : null}
                    <Header
                        hasLeadCollection={Boolean(leadCollectionProps)}
                        siteTitle={data.site.siteMetadata.title}
                        links={links || defaultLinks}
                    />
                    <div className={layoutContainer}>
                        {leadCollectionProps ? (
                            <LeadCollection
                                city={leadCollectionProps.city}
                                state={leadCollectionProps.state}
                                service={leadCollectionProps.service}
                                headline={leadCollectionProps.headline}
                                subHeadline={leadCollectionProps.subHeadline}
                            />
                        ) : null}
                        <main data-testid="layout-main">{children}</main>
                    </div>
                </div>
                {isHomePage ? (
                    <HomePageFooter
                        states={states}
                        hasLeadCollection={Boolean(leadCollectionProps)}
                    />
                ) : (
                    <Footer hasLeadCollection={Boolean(leadCollectionProps)} />
                )}

                {leadCollectionProps ? (
                    <div
                        className={`${getQuotesCta} ${scrollPosition < 665 ? hideCta : ''}`}
                        data-testid="lower-cta"
                    >
                        <JumpLinkButton styleOverride={{ width: 'calc(100vw - 54px)' }} />
                    </div>
                ) : null}
            </div>
        </LayoutProvider>
    );
};

Layout.propTypes = {
    states: PropTypes.arrayOf(PropTypes.shape({})),
    isHomePage: PropTypes.bool,
    links: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.node.isRequired,
    leadCollectionProps: PropTypes.shape({
        headline: PropTypes.string,
        subHeadline: PropTypes.string,
        city: PropTypes.shape({
            name: PropTypes.string,
        }),
        state: PropTypes.shape({
            slug: PropTypes.string,
            name: PropTypes.string,
        }),
        service: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    metaDescription: PropTypes.string,
    metaTitle: PropTypes.string,
    metaPageNumber: PropTypes.number,
    meta: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
        }),
    ),
    noindex: PropTypes.bool,
    schemaOrgJSONLD: PropTypes.shape({
        '@context': PropTypes.string,
        '@type': PropTypes.string,
        openingHours: PropTypes.string,
        url: PropTypes.string,
        priceRange: PropTypes.string,
        image: PropTypes.string,
        logo: PropTypes.string,
        name: PropTypes.string,
        telephone: PropTypes.string,
        sameAs: PropTypes.arrayOf(PropTypes.string),
        address: PropTypes.shape({
            addressLocality: PropTypes.string,
            addressRegion: PropTypes.string,
            postalCode: PropTypes.string,
            streetAddress: PropTypes.string,
        }),
        aggregateRating: PropTypes.shape({
            '@type': PropTypes.string,
            ratingValue: PropTypes.number,
            reviewCount: PropTypes.number,
            reviews: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

Layout.defaultProps = {
    isHomePage: false,
    states: [],
    leadCollectionProps: null,
    links: null,
    metaDescription: null,
    metaTitle: null,
    metaPageNumber: 0,
    meta: [],
    schemaOrgJSONLD: {},
    noindex: false,
};

export default Layout;
