import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import headerStyles from './header.module.scss';
import Logo from '../../assets/wikilawn-logo-green.svg';
import LogoMobile from '../../assets/wikilawn-logo-green-mobile.svg';
import Hamburger from './components/hamburger/hamburger';
import MobileDropDown from './components/mobileDropDown/mobileDropDown';
import MainLinks from './components/mainLinks/mainLinks';
import JumpLinkButton from '../jumpLinkButton/jumpLinkButton';

const Header = ({ links, hasLeadCollection }) => {
    const {
        headerContainer,
        headerInnerContainer,
        headerLowerArea,
        headerLogoLink,
        headerLogoDesktop,
        headerLogoMobile,
    } = headerStyles;

    let more;
    let sum = 0;
    let processedLinks = links;

    _.forEach(links, (link, idx) => {
        sum += _.size(link.navText);
        if (sum >= 60) {
            more = {
                to: null,
                navText: 'More',
                childrenLinks: _.drop(links, idx),
            };
            processedLinks = _.concat(_.dropRight(links, _.size(links) - idx), more);
            return false;
        }
        return true;
    });

    const [showDropDown, setShowDropDown] = useState(0);
    const [dropDownLinks, setDropDownLinks] = useState([]);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [showMobileDropDown, setShowMobileDropDown] = useState(0);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, true);

        return () => {
            document.removeEventListener('mousedown', handleClick, true);
        };
    }, []);

    const handleClick = (e) => {
        // if we click on the menu, or a link inside the menu (links will close the menu automatically),
        // don't set the state to close the menu

        const { className } = e.target;
        let ignoreClick = false;

        if (className) {
            ignoreClick = _.includes(e.target.className, 'headerMenu')
                || _.includes(className, 'link')
                // this is a special case where you may click on the svg part of the chevron
                || _.includes(className.baseVal, 'link');
        }

        if (ignoreClick) {
            return;
        }

        setShowDropDown(0);
        setShowMobileNav(false);
    };

    const renderNavLinks = () => {
        const { headerDesktopNav } = headerStyles;

        return (
            <nav>
                <div data-testid="main-links" className={headerDesktopNav}>
                    <MainLinks
                        setDropDownLinks={setDropDownLinks}
                        setShowMobileNav={setShowMobileNav}
                        setShowDropDown={setShowDropDown}
                        showDropDown={showDropDown}
                        processedLinks={processedLinks}
                        dropDownLinks={dropDownLinks}
                    />

                    {hasLeadCollection ? (
                        <div>
                            <JumpLinkButton styleOverride={{ marginLeft: 15 }} />
                        </div>
                    ) : null}

                </div>

                <Hamburger
                    setShowMobileNav={setShowMobileNav}
                    setShowDropDown={setShowDropDown}
                    setShowMobileDropDown={setShowMobileDropDown}
                    showMobileNav={showMobileNav}
                />
            </nav>
        );
    };

    // render final
    return (
        <header className={headerContainer} data-testid="main">
            <div className={headerInnerContainer}>
                <div className={headerLowerArea}>
                    <Link className={headerLogoLink} to="/" aria-label="Wikilawn">
                        <Logo className={`${headerLogoDesktop} link`} />
                        <LogoMobile className={headerLogoMobile} />
                    </Link>
                    {renderNavLinks()}
                    {showMobileNav ? (
                        <MobileDropDown
                            showMobileDropDown={showMobileDropDown}
                            setShowMobileDropDown={setShowMobileDropDown}
                            setShowMobileNav={setShowMobileNav}
                            links={links}
                            dropDownLinks={dropDownLinks}
                            setDropDownLinks={setDropDownLinks}
                            hasLeadCollection={hasLeadCollection}
                        />
                    ) : null}
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({})),
    hasLeadCollection: PropTypes.bool,
};

Header.defaultProps = {
    links: null,
    hasLeadCollection: false,
};

export default Header;
