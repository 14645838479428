/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import loadable from '@loadable/component';
import useForm, { FormContext } from 'react-hook-form';
import { leadCollectionServices } from '../../constants';
import leadCollectionStyles from './leadCollection.module.scss';
import LayoutContext from '../layout/layoutContext';

const AddressAutocomplete = loadable(() => import('../addressAutocomplete/addressAutocomplete'));
const SelectablePills = loadable(() => import('../selectablePills/selectablePills'));
const PressCoverageBanner = loadable(() => import('../pressCoverageBanner/pressCoverageBanner'));
const Button = loadable(() => import('../button/button'));


const LeadCollection = ({
    city, state, service, headline, subHeadline,
}) => {
    const methods = useForm();
    const { setIsLoading } = useContext(LayoutContext);
    // need to check for a state for the Washington, DC case since it doesn't have one
    const stateSlug = _.get(state, 'slug');

    const {
        container,
        innerContainer,
        subHeadlineText,
        backgroundImage,
        form,
        selectContainer,
        addressContainer,
        backgroundImageContainer,
        buttonWrapper,
        overlay,
    } = leadCollectionStyles;

    const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 75 ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    `);

    const submitForm = async() => {
        await methods.triggerValidation();

        const { addressAutocomplete, selectablePills } = methods.getValues();

        // if Other is chosen, don't make call to /check and just nav to the form
        if (!_.get(selectablePills, 'option.value')) {
            navigate('/get-quote', {
                state: {
                    selectedAddress: addressAutocomplete,
                    selectedService: null,
                },
            });
            return;
        }

        const {
            street,
            city: formCity,
            state: formState,
            zip,
            formatted_address,
        } = addressAutocomplete;

        setIsLoading(true);

        axios
            .post(`${process.env.WIKILAWN_API_ENDPOINT}/check`, {
                task_id: selectablePills.option.value,
                street,
                city: formCity,
                state: formState,
                zip,
                country: 'usa',
                address: formatted_address,
            })
            .then(() => {
                navigate('/get-quote', {
                    state: {
                        selectedAddress: addressAutocomplete,
                        selectedService: selectablePills,
                    },
                });
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.warn(error);
                setIsLoading(false);
            });
    };

    return (
        <div className={container} id="get-quote" data-testid="lead-collection">

            <div className={backgroundImageContainer}>
                <Img
                    fluid={image.placeholderImage.childImageSharp.fluid}
                    className={backgroundImage}
                    loading="eager"
                    fadeIn={false}
                />
                {/* banner background here so it can go full width */}
                <div className={overlay} />
            </div>

            <div className={innerContainer}>
                <div>
                    <h1 data-testid="headline">
                        {headline || `Get ${service.name} in ${city.name}${stateSlug ? `, ${_.toUpper(stateSlug)}` : ''}`}
                    </h1>

                    <h3 data-testid="sub-headline" className={subHeadlineText}>
                        {subHeadline || `Find ${_.toLower(service.name)} near you`}
                    </h3>
                </div>
                <div>
                    <FormContext {...methods}>
                        <h4>Choose a service</h4>
                        <form className={form} onSubmit={methods.handleSubmit(submitForm)}>
                            <div className={selectContainer}>
                                <SelectablePills options={leadCollectionServices} />
                            </div>
                            <div className={addressContainer}>
                                <AddressAutocomplete onSubmit={methods.handleSubmit(submitForm)} />
                            </div>
                            <div className={buttonWrapper}>
                                <Button
                                    onKeyDown={methods.handleSubmit(submitForm)}
                                    onClick={methods.handleSubmit(submitForm)}
                                    text="Go"
                                    type="submit"
                                    styleOverride={{ width: '100%', fontSize: 20, padding: '8px 20px' }}
                                />
                            </div>
                        </form>
                    </FormContext>
                </div>
                <PressCoverageBanner />
            </div>

        </div>
    );
};

LeadCollection.propTypes = {
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    city: PropTypes.shape({
        name: PropTypes.string,
    }),
    state: PropTypes.shape({
        slug: PropTypes.string,
    }),
    service: PropTypes.shape({
        name: PropTypes.string,
    }),
};

LeadCollection.defaultProps = {
    headline: null,
    subHeadline: null,
    city: null,
    state: null,
    service: null,
};

export default LeadCollection;
