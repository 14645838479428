import React from 'react';
import Proptypes from 'prop-types';
import _ from 'lodash';
import buttonStyles from './button.module.scss';

const Button = ({
    text, type, onClick, onKeyDown, secondary, styleOverride,
}) => {
    const { button, secondaryButton } = buttonStyles;

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            className={secondary ? secondaryButton : button}
            style={styleOverride}
            onMouseDown={(e) => {
                // prevent outline when clicking
                e.preventDefault();
                onClick();
            }}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    onKeyDown();
                }
            }}
            type={type}
            data-testid="button"
        >
            {text}
        </button>
    );
};

Button.propTypes = {
    text: Proptypes.string.isRequired,
    type: Proptypes.string,
    onClick: Proptypes.func,
    onKeyDown: Proptypes.func,
    secondary: Proptypes.bool,
    styleOverride: Proptypes.shape({}),
};

Button.defaultProps = {
    type: 'button',
    onClick: _.noop,
    onKeyDown: _.noop,
    secondary: false,
    styleOverride: {},
};

export default Button;
