import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

function SEO({
    description, lang, meta, title, noindex, schemaOrgJSONLD,
}) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate="%s"
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'robots',
                    content: noindex ? 'noindex' : 'index',
                },
            ].concat(meta)}
        >
            {_.size(schemaOrgJSONLD) ? (
                <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
            ) : null}
        </Helmet>
    );
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string.isRequired,
    schemaOrgJSONLD: PropTypes.shape({
        '@context': PropTypes.string,
        '@type': PropTypes.string,
        openingHours: PropTypes.string,
        url: PropTypes.string,
        priceRange: PropTypes.string,
        logo: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string,
        telephone: PropTypes.string,
        sameAs: PropTypes.arrayOf(PropTypes.string),
        address: PropTypes.shape({
            addressLocality: PropTypes.string,
            addressRegion: PropTypes.string,
            postalCode: PropTypes.string,
            streetAddress: PropTypes.string,
        }),
        aggregateRating: PropTypes.shape({
            '@type': PropTypes.string,
            ratingValue: PropTypes.number,
            reviewCount: PropTypes.number,
            reviews: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
    noindex: PropTypes.bool,
};

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
    schemaOrgJSONLD: {},
    noindex: false,
};

export default SEO;
