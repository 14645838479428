import { Link } from 'gatsby';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Chevron from '../../../../assets/chevron.svg';
import mobileDropDownStyles from './mobileDropDown.module.scss';
import JumpLinkButton from '../../../jumpLinkButton/jumpLinkButton';

const MobileDropDown = ({
    showMobileDropDown,
    setShowMobileDropDown,
    setDropDownLinks,
    setShowMobileNav,
    links,
    dropDownLinks,
    hasLeadCollection,
}) => {
    const {
        mobileDropDownContainer,
        mobileDropDownMobileChevron,
        mobileDropDownMobileChevronActive,
        mobileDropDownMenu,
    } = mobileDropDownStyles;

    const renderMobileSubMenu = (menu) => {
        if (menu !== showMobileDropDown) {
            return null;
        }
        const { mobileDropDownMobileSubMenu } = mobileDropDownStyles;

        return (
            <div
                data-testid={`mobile-sub-menu-${menu}`}
                className={`${mobileDropDownMobileSubMenu}`}
            >
                {_.map(dropDownLinks, (link) => {
                    if (link.to && link.navText && _.includes(link.to, '#')) {
                        // always use <a/> tags if linking to same page, not <Link/>
                        return (
                            <a
                                data-testid="mobile-sub-menu-link"
                                onClick={() => {
                                    setShowMobileNav(false);
                                }}
                                className="link"
                                href={link.to}
                                key={link.to}
                            >
                                {link.navText}
                            </a>
                        );
                    }
                    if (link.to && link.navText) {
                        return (
                            <Link
                                data-testid="mobile-sub-menu-link"
                                onClick={() => {
                                    setShowMobileNav(false);
                                }}
                                className="link"
                                to={link.to}
                                activeClassName="activeLink"
                                key={link.to}
                            >
                                {link.navText}
                            </Link>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    return (
        <nav
            data-testid="mobile-menu"
            className={`${mobileDropDownContainer} ${mobileDropDownMenu}`}
        >
            {_.map(links, (link, idx) => {
                const menu = idx + 1;
                const isCurrentActiveLink = menu === showMobileDropDown;

                if (link.childrenLinks && link.navText) {
                    return (
                        <div key={link.navText}>
                            <span
                                data-testid="mobile-collapse-link"
                                tabIndex="0"
                                role="button"
                                aria-pressed="false"
                                className={`${isCurrentActiveLink ? 'activeLink' : ''} link`}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    setDropDownLinks(link.childrenLinks);
                                    setShowMobileDropDown(isCurrentActiveLink ? 0 : menu);
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 32) {
                                        setDropDownLinks(link.childrenLinks);
                                        setShowMobileDropDown(isCurrentActiveLink ? 0 : menu);
                                    }
                                }}
                            >
                                {link.navText}
                                <Chevron
                                    className={`${mobileDropDownMobileChevron} ${
                                        isCurrentActiveLink ? mobileDropDownMobileChevronActive : ''
                                    } link`}
                                />
                            </span>
                            {showMobileDropDown ? renderMobileSubMenu(menu) : null}
                        </div>
                    );
                }
                if (link.to && link.navText && _.includes(link.to, '#')) {
                    // always use <a/> tags if linking to same page, not <Link/>
                    return (
                        <a
                            data-testid="mobile-main-link"
                            onClick={() => {
                                setShowMobileNav(false);
                            }}
                            href={link.to}
                            className="link"
                            key={link.navText}
                        >
                            {link.navText}
                        </a>
                    );
                }
                if (link.to && link.navText) {
                    return (
                        <Link
                            data-testid="mobile-main-link"
                            onClick={() => {
                                setShowMobileNav(false);
                            }}
                            to={link.to}
                            className="link"
                            activeClassName="activeLink"
                            key={link.navText}
                        >
                            {link.navText}
                        </Link>
                    );
                }
                return null;
            })}
            { hasLeadCollection ? (
                <JumpLinkButton styleOverride={{ marginTop: 15 }} onClick={setShowMobileNav} />
            ) : null}
        </nav>
    );
};

MobileDropDown.propTypes = {
    showMobileDropDown: PropTypes.number.isRequired,
    setShowMobileDropDown: PropTypes.func.isRequired,
    setShowMobileNav: PropTypes.func.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setDropDownLinks: PropTypes.func.isRequired,
    dropDownLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasLeadCollection: PropTypes.bool,
};

MobileDropDown.defaultProps = {
    hasLeadCollection: false,
};

export default MobileDropDown;
