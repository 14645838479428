/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../assets/spinner.svg';
import Completed from '../../assets/form-submitted.svg';
import loadingStyles from './loading.module.scss';

const Loading = ({ hasCompleted }) => {
    const {
        container, textAndSpinner, text, spinner, completed,
    } = loadingStyles;

    return (
        <div className={container} data-testid="loading">
            {
                hasCompleted ? (
                    <div className={textAndSpinner} data-testid="completed">
                        <Completed className={completed} />
                        <h2 className={text}>The form has been submitted!</h2>
                    </div>
                ) : (
                    <div className={textAndSpinner} data-testid="currently-loading">
                        <Spinner className={spinner} />
                        <h2 className={text}>This will take just a moment</h2>
                    </div>
                )
            }
        </div>
    );
};

Loading.propTypes = {
    hasCompleted: PropTypes.bool,
};

Loading.defaultProps = {
    hasCompleted: false,
};

export default Loading;
