import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import jumpLinkButtonStyles from './jumpLinkButton.module.scss';

const JumpLinkButton = ({
    text, id, styleOverride, onClick,
}) => {
    const { button } = jumpLinkButtonStyles;

    //  need to use a tag here since we are relying on smooth-scroll href for jump links
    return (
        <a
            style={styleOverride}
            // need link class to allow jump-link to work, see header --> handleClick
            className={`${button} link`}
            onClick={() => {
                onClick(false);
            }}
            href={`#${id}`}
            data-testid="jump-button"
        >
            {text}
        </a>

    );
};

JumpLinkButton.propTypes = {
    text: PropTypes.string,
    id: PropTypes.string,
    styleOverride: PropTypes.shape({}),
    onClick: PropTypes.func,
};

JumpLinkButton.defaultProps = {
    text: 'Get Quotes',
    id: 'get-quote',
    styleOverride: {},
    onClick: _.noop,
};


export default JumpLinkButton;
