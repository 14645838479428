import { Link } from 'gatsby';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dropDownMenuStyles from './dropDownMenu.module.scss';

const DropDownMenu = ({
    menu, showDropDown, dropDownLinks, setShowDropDown,
}) => {
    if (menu !== showDropDown) { return null; }
    const { dropDownMenu } = dropDownMenuStyles;

    return (
        <div
            className={`${dropDownMenu}`}
            data-testid={`drop-down-${menu}`}
        >
            {
                _.map(dropDownLinks, (link) => {
                    if (link.to && link.navText && _.includes(link.to, '#')) {
                        // always use <a/> tags if linking to same page, not <Link/>
                        return (
                            <a
                                data-testid="main-link"
                                href={link.to}
                                key={link.navText}
                                onClick={() => {
                                    setShowDropDown(0);
                                }}
                                className="link"
                            >
                                {link.navText}
                            </a>
                        );
                    }
                    if (link.to && link.navText) {
                        return (
                            <Link
                                key={link.to}
                                data-testid="drop-down-link"
                                onClick={() => {
                                    setShowDropDown(0);
                                }}
                                className="link"
                                to={link.to}
                            >
                                {link.navText}
                            </Link>
                        );
                    }
                    return null;
                })
            }
        </div>
    );
};

DropDownMenu.propTypes = {
    showDropDown: PropTypes.number.isRequired,
    menu: PropTypes.number.isRequired,
    dropDownLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setShowDropDown: PropTypes.func.isRequired,
};

export default DropDownMenu;
