import React from 'react';
import PropTypes from 'prop-types';
import FooterCopyrightStyles from './footerCopyright.module.scss';

const FooterCopyright = ({ hasLeadCollection }) => {
    const { copyrightSection, copyrightText, extraHeight } = FooterCopyrightStyles;

    return (
        <section
            className={`${copyrightSection} ${hasLeadCollection ? extraHeight : ''}`}
            data-testid="copyright"
        >
            <h4 className={copyrightText}>
                {new Date().getFullYear()} WIKILAWN.COM, ALL RIGHTS RESERVED
            </h4>
        </section>
    );
};

FooterCopyright.propTypes = {
    hasLeadCollection: PropTypes.bool,
};

FooterCopyright.defaultProps = {
    hasLeadCollection: false,
};

export default FooterCopyright;
