/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import selectablePillsStyles from './selectablePills.module.scss';

const SelectablePills = ({ options, name }) => {
    const { container, pill, isSelected } = selectablePillsStyles;
    const [selected, setSelected] = useState(options[0]);
    const { setValue, register } = useFormContext();

    const handleSelect = (option) => {
        setSelected(option);
        setValue(name, { option });
    };

    useEffect(() => {
        register({ name });
        setValue(name, { option: selected });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={container}>
            {_.map(options, (option) => {
                return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                        className={`${pill} ${selected.label === option.label ? isSelected : ''}`}
                        key={option.label}
                        onClick={() => {
                            handleSelect(option);
                        }}
                        data-testid="pill"
                    >
                        <span>{option.label}</span>
                    </div>
                );
            })}
        </div>
    );
};

SelectablePills.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
        })
    ).isRequired,
    name: PropTypes.string,
};

SelectablePills.defaultProps = {
    name: 'selectablePills',
};

export default SelectablePills;
