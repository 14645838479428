/* eslint-disable camelcase */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { lsAddressHelpers } from './addressHelpers';
import addressAutocompleteStyles from './addressAutocomplete.module.scss';
import MapMarkerPrimary from '../../assets/map-marker.svg';
import MapMarkerTertiary from '../../assets/map-marker-tertiary.svg';
import MapMarkerGray from '../../assets/map-marker-gray.svg';
import Button from '../button/button';

const AddressAutocomplete = ({ name, initialValue, onSubmit }) => {
    const [inputAddress, setInputAddress] = useState(_.get(initialValue, 'formatted_address', ''));
    const {
        container,
        input,
        leadCollectionInput,
        listVisable,
        mapMarker,
        leadCollectionMapMarker,
        googleLogo,
        suggestionItem,
        suggestionItemActive,
        leadCollectionItemActive,
        autocompleteList,
        leadCollectionAutocompleteList,
        suggestionMainText,
        suggestionSecondaryText,
        suggestionMapMarker,
        leadCollectionInvalid,
        inputInvalid,
        leadCollectionInvalidWarning,
        invalidWarning,
        redBeforeBorder,
        floatingLabel,
        buttonWrapper,
    } = addressAutocompleteStyles;
    const { register, errors, triggerValidation, setValue } = useFormContext();

    const handleSelect = (typedAddress, placeId) => {
        const source = placeId
            ? lsAddressHelpers.getDetailsByPlaceId(placeId)
            : geocodeByAddress(typedAddress);

        source
            .then((result) => {
                const {
                    city,
                    state,
                    street,
                    zip,
                    formatted_address,
                } = lsAddressHelpers.formatPlace({
                    place: _.isArray(result) ? result[0] : result,
                });

                setInputAddress(formatted_address);
                setValue(name, {
                    zip,
                    city,
                    state,
                    street,
                    formatted_address,
                });
                triggerValidation({ name });
            })
            .catch(() => {
                setValue(name);
                triggerValidation({ name });
            });
    };

    useEffect(() => {
        register(
            {
                name,
            },
            {
                validate: {
                    isNotEmpty: (value) => {
                        if (!_.isNil(value) || inputAddress !== '') {
                            return true;
                        }
                        return 'Please enter an address with valid zipcode';
                    },
                    hasZip: (value) => {
                        if (_.get(value, 'zip')) {
                            return true;
                        }
                        return 'Address needs a zip code';
                    },
                    hasAddress: (value) => {
                        if (_.get(value, 'formatted_address')) {
                            return true;
                        }
                        return 'Please enter a valid address';
                    },
                },
            }
        );

        if (initialValue) {
            setValue(name, { ...initialValue });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useLeadCollectionStyle = _.isFunction(onSubmit);
    const isInvalid = !_.isEmpty(_.get(errors, 'addressAutocomplete'));

    const getInputClassNames = (suggestions) => {
        if (useLeadCollectionStyle) {
            return `${leadCollectionInput} ${isInvalid ? leadCollectionInvalid : ''} ${
                _.size(suggestions) ? listVisable : ''
            }`;
        }
        if (isInvalid) {
            return inputInvalid;
        }

        return input;
    };

    return (
        <PlacesAutocomplete
            value={inputAddress}
            onChange={(currentInput) => {
                return setInputAddress(currentInput);
            }}
            onSelect={handleSelect}
            onError={() => {
                return triggerValidation({ name });
            }}
            googleCallbackName="initAutocomplete"
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                    <div className={container}>
                        {useLeadCollectionStyle ? (
                            <MapMarkerTertiary className={leadCollectionMapMarker} />
                        ) : (
                            <MapMarkerPrimary className={mapMarker} />
                        )}
                        <label htmlFor="address-input">
                            <input
                                required
                                aria-label="Your Address"
                                type="text"
                                data-testid="addressInput"
                                id="address-input"
                                className={getInputClassNames(suggestions)}
                                {...getInputProps({
                                    placeholder: useLeadCollectionStyle
                                        ? ''
                                        : 'Your Street Address',
                                    onBlur: () => {
                                        if (_.size(suggestions)) {
                                            const head = _.head(suggestions);

                                            // Proactively select the first suggested place
                                            handleSelect(
                                                head.formattedSuggestion.mainText,
                                                head.placeId
                                            );
                                        } else {
                                            handleSelect(inputAddress);
                                        }
                                    },
                                })}
                            />
                            {useLeadCollectionStyle ? (
                                <span className={floatingLabel}>Your Street Address</span>
                            ) : null}
                            {useLeadCollectionStyle ? (
                                <div className={buttonWrapper}>
                                    <Button
                                        onKeyDown={onSubmit}
                                        onClick={onSubmit}
                                        text="Go"
                                        type="submit"
                                        styleOverride={{
                                            height: '60px',
                                            width: '130px',
                                            borderRadius: 32,
                                            fontSize: 25,
                                        }}
                                    />
                                </div>
                            ) : null}
                        </label>
                        {_.size(suggestions) ? (
                            <div
                                className={`${
                                    useLeadCollectionStyle
                                        ? leadCollectionAutocompleteList
                                        : autocompleteList
                                } ${isInvalid ? redBeforeBorder : ''}`}
                            >
                                {_.map(suggestions, (suggestion) => {
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion)}
                                            className={`${
                                                suggestion.active
                                                    ? suggestionItemActive
                                                    : suggestionItem
                                            } ${
                                                useLeadCollectionStyle
                                                    ? leadCollectionItemActive
                                                    : ''
                                            }`}
                                            key={suggestion.placeId || suggestion.index}
                                        >
                                            {suggestion.active && !useLeadCollectionStyle ? (
                                                <MapMarkerPrimary className={suggestionMapMarker} />
                                            ) : (
                                                <MapMarkerGray className={suggestionMapMarker} />
                                            )}
                                            {suggestion.active && useLeadCollectionStyle ? (
                                                <MapMarkerTertiary
                                                    className={suggestionMapMarker}
                                                />
                                            ) : (
                                                <MapMarkerGray className={suggestionMapMarker} />
                                            )}
                                            <div>
                                                <span className={suggestionMainText}>
                                                    {suggestion.formattedSuggestion.mainText}
                                                </span>
                                                <span className={suggestionSecondaryText}>
                                                    {suggestion.formattedSuggestion.secondaryText}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className={googleLogo}>
                                    <img
                                        src="//maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png"
                                        height="12"
                                        alt="Powered By Google"
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!_.isEmpty(errors.addressAutocomplete) && _.isEmpty(suggestions) ? (
                            <p
                                className={
                                    useLeadCollectionStyle
                                        ? leadCollectionInvalidWarning
                                        : invalidWarning
                                }
                            >
                                {errors.addressAutocomplete.message}
                            </p>
                        ) : null}
                    </div>
                );
            }}
        </PlacesAutocomplete>
    );
};

AddressAutocomplete.propTypes = {
    name: PropTypes.string,
    initialValue: PropTypes.shape({
        city: PropTypes.string,
        formatted_address: PropTypes.string,
        state: PropTypes.string,
        street: PropTypes.string,
        zip: PropTypes.string,
    }),
    onSubmit: PropTypes.func,
};

AddressAutocomplete.defaultProps = {
    name: 'addressAutocomplete',
    initialValue: null,
    onSubmit: null,
};

export default AddressAutocomplete;
