import { Link } from 'gatsby';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Chevron from '../../../../assets/chevron.svg';
import DropDownMenu from '../dropDownMenu/dropDownMenu';
import mainLinksStyles from './mainLinks.module.scss';

const MainLinks = ({
    setDropDownLinks, setShowMobileNav, setShowDropDown,
    showDropDown, processedLinks, dropDownLinks,
}) => {
    const {
        mainLinksChevron, childLinksContianer, mainLinksNavItem,
    } = mainLinksStyles;

    const toggleMenus = ({ childLinks, menu }) => {
        setDropDownLinks(childLinks);
        setShowMobileNav(false);
        setShowDropDown(menu === showDropDown ? 0 : menu);
    };

    return (
        <>
            {
                _.map(processedLinks, (link, idx) => {
                    if (link.childrenLinks && link.navText) {
                        const menu = idx + 1;

                        return (
                            <div key={link.navText} className={childLinksContianer}>
                                <span
                                    tabIndex="0"
                                    role="button"
                                    aria-pressed="false"
                                    data-testid="collapse-link"
                                    className={`${mainLinksNavItem} link`}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        toggleMenus({ childLinks: link.childrenLinks, menu });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13 || e.keyCode === 32) {
                                            toggleMenus({ childLinks: link.childrenLinks, menu });
                                        }
                                    }}
                                >
                                    {link.navText}
                                    <Chevron
                                        className={mainLinksChevron}
                                    />
                                </span>
                                {showDropDown
                                    ? <DropDownMenu
                                        showDropDown={showDropDown}
                                        menu={menu}
                                        dropDownLinks={dropDownLinks}
                                        setShowDropDown={setShowDropDown}
                                    />
                                    : null}
                            </div>
                        );
                    }
                    if (link.to && link.navText && _.includes(link.to, '#')) {
                        // always use <a/> tags if linking to same page, not <Link/>
                        return (
                            <a
                                data-testid="main-link"
                                href={link.to}
                                key={link.navText}
                                className={`${mainLinksNavItem}`}
                            >
                                {link.navText}
                            </a>
                        );
                    }
                    if (link.to && link.navText) {
                        return (
                            <Link
                                data-testid="main-link"
                                to={link.to}
                                activeClassName="activeLink"
                                key={link.navText}
                                className={`${mainLinksNavItem} link`}
                            >
                                {link.navText}
                            </Link>
                        );
                    }
                    return null;
                })
            }
        </>
    );
};

MainLinks.propTypes = {
    setDropDownLinks: PropTypes.func.isRequired,
    setShowMobileNav: PropTypes.func.isRequired,
    setShowDropDown: PropTypes.func.isRequired,
    showDropDown: PropTypes.number.isRequired,
    processedLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dropDownLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MainLinks;
